<template>
  <el-row justify="start">
    <el-col :span="2">
      <el-image
        style="width: 58px; height: 58px"
        :src="require('./assets/Logo512x512.png')"
        fit="fill"
      />
    </el-col>
    <el-col :span="18">
      <h2>深圳市赤炎数据信息技术有限公司</h2>
    </el-col>
    <el-col :span="4">
      <el-menu mode="horizontal" router default-active="/">
        <el-menu-item index="/">首页</el-menu-item>
        <el-menu-item index="/about">关于</el-menu-item>
      </el-menu>
    </el-col>
  </el-row>

  <router-view />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}
</style>
